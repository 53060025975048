<template>
  <CRow>
    <CCol col="12">
      <!--CCard no-header :accentColor="isFormDirty ? (isFormValid && !file_errors.length ? 'success' : 'danger') : ''"-->
      <CButton class="float-right mb-2" color="warning" @click="show_upload = !show_upload">{{ $t(show_upload ? 'Back' : 'Upload file') }}</CButton>
    </CCol>

    <CCol v-if="show_upload" col="12">
      <CCard no-header :accentColor="!isDisabled ? (!file_errors.length ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ $t('Data import') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard :title="type_name">
              <AMultiSelect :horizontal="{ input: 'col-sm-9' }" label="Bank account" v-model="form.bank_account_id" :config="{ label: 'account_name' }" :options="lists.bank_accounts" @select="onBankAccountChanged" />
              <AMultiSelect :disabled="!form.bank_account_id" :horizontal="{ input: 'col-sm-9' }" label="Type" v-model="form.bank_statement_file_type_id" :options="bank_statement_file_types" @select="onTypeChanged" />
              <hr />
              <!--AMultiSelect :horizontal="{ input: 'col-sm-9' }" label="Separator" v-model="form.separator" :options="separators" /-->
              <AInputFile :disabled="!form.bank_statement_file_type_id" ref="files" name="files" label="Upload Excel file" placeholder="" :accept="form.extensions" :multiple="isMultiple" @change="onFileSelected" />
              <!--ACheckBox v-if="show_append && !isUpdate" label="Adicionar" placeholder="Adicionar información a la existente." v-model="form.append" />
              <ACheckBox label="Encabezados" placeholder="Contiene encabezados (Primera fila con nombre del campo)." v-model="form.heading_row" /-->
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" color="info" :disabled="isDisabled" @click="submit">{{ $t('Upload file') + (isMultiple ? '(s)' : '') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>

      <!--CAlert :color="file_errors.length ? 'danger' : 'success'" :show.sync="alertCounter" closeButton-->
      <CAlert :color="status.class || 'danger'" :show="!!alertCounter" closeButton>
        <p v-html="status.message" />

        <div v-if="resumeErrors.length">
          <b>{{ $t('Errors resume') }}</b>
          <ul>
            <li v-for="error of resumeErrors" :key="error">{{ error }}</li>
          </ul>
        </div>
        <!--CProgress :max="10" :value="alertCounter" height="3px" :color="file_errors.length ? 'danger' : 'success'" animate /-->
      </CAlert>

      <CCard no-header v-if="file_errors.length" accentColor="danger">
        <CCardBody>
          <h3 style="float: left;">{{ $t('Data import errors') }}:</h3>
          <CDataTable class="table-sm" striped :items="file_errors" :fields="fields" items-per-page-select :items-per-page="20" pagination sorter>
            <template #errors="{item}">
              <td>
                <span v-for="(error, index) of item.errors" :key="'E' + index">{{ index + 1 }} - {{ error }}<br /></span>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <template v-else>
      <CCol col="12">
        <div class="m-auto table-center">
          <ACRUDTable
            responsive
            addTableClasses="table-responsive table-center"
            ref="monthly_table"
            :fields="[
              //
              helpers.monthName,
              { key: 'month_id', _classes: 'hide' },
              { ...helpers.smallValue, key: 'rows' },
              { ...helpers.smallValue, key: 'identified' },
              { ...helpers.smallValue, key: 'non_identified' },
              { ...helpers.smallValue, key: 'aux' },
              helpers.currencyValue,
              { ...helpers.currencyValue, key: 'applied' },
              { ...helpers.currencyValue, key: 'pending' }
            ]"
            :key="'M' + tables.monthly.length"
            :items="tables.monthly"
            :config="{
              name: 'bank_statement_month',
              display_name: 'Listado de archivos bancarios (' + (this.transaction_type === 'INGR' ? 'Ingresos' : 'Egresos') + ')',
              noCreate: true,
              noOptions: 'hide'
            }"
            :sorterValue="{ column: 'month_id', asc: false }"
          />
        </div>

        <ACRUDTable
          ref="bank_statements_table"
          :fields="[
            //
            { key: 'id', label: 'ExID' },
            helpers.monthName,
            { key: 'month_id', _classes: 'hide' },
            'bank',
            'dates',
            { ...helpers.smallValue, key: 'rows' },
            { ...helpers.smallValue, key: 'identified' },
            { ...helpers.smallValue, key: 'non_identified' },
            { ...helpers.smallValue, key: 'aux' },
            helpers.currencyValue,
            { ...helpers.currencyValue, key: 'applied' },
            { ...helpers.currencyValue, key: 'pending' },
            { key: 'property_file_id', label: 'File' },
            { key: 'bank_statement_file_type', label: 'Type' }
          ]"
          :key="'S' + tables.bank_statements.length"
          :items="tables.bank_statements"
          :config="{
            name: 'bank_statement',
            //display_name: 'Listado de archivos bancarios (' + (this.transaction_type === 'INGR' ? 'Ingresos' : 'Egresos') + ')',
            noTitle: true,
            noCreate: true,
            noOptions: 'hide'
          }"
          :sorterValue="{ column: 'month_id', asc: false }"
          @preparedHelpers="onPreparedHelpers"
        />

        {{ filter }}
        <AMultiSelect
          v-model="filter"
          :horizontal="{ label: 'col-sm-3' }"
          :options="[
            { id: 'All', name: 'All' },
            { id: 'Identified', name: 'Identified' },
            { id: 'Non identified', name: 'Non identified' }
          ]"
        />

        <ACRUDTable
          ref="pending_table"
          size="sm"
          :fields="[
            //
            'id',
            { key: 'bank_statement_id', label: 'ExID', _style: 'width: 120px;' },
            { key: 'month', helper: { id: 'payment_month_id', parser: month_id => this.parsers.periodName(month_id) }, _style: 'width: 120px;', _classes: 'v-center' },
            { ...helpers.datetimeDate, key: 'date' },
            'channel',
            { key: 'reference_1', label: 'Reference' },
            { ...helpers.currencyValue, key: 'statement_value', label: 'Bank value' },
            { key: 'value', label: 'Final value', _style: 'width: 150px;' },
            'building_id',
            //{ key: 'building_id', select: { options: lists.buildings, config: { basic: true, label: 'identifier' }, onChange: onBuildingChanged } },
            { key: '_details', label: 'Observations' },
            { key: 'options', _style: 'width: 150px;' }
          ]"
          :key="'P' + tables.pending.length"
          :items="pending"
          :config="{
            name: 'bank_statement_details',
            display_name: $t('Pending payments'),
            noCreate: true,
            noOptions: 'hide'
          }"
          asorterValue="{ column: 'id', asc: false }"
          @preparedHelpers="onPreparedHelpers"
        >
          <template #reference_1="{item}">
            <td>
              <template v-if="!item.is_aux">
                <!-- // TODO: function ? -->
                <CButton color="secondary" size1="sm" class="ml-4" style="padding: 1px 4px;" v-c-popover="{ header: $t('Extra data') + '...', content: '<table ><tr><td><b>' + $t('Reference') + `2:</b>  ${item.reference_2 || '[vacia]'}</td></tr><tr><td><b>` + $t('Subsidiary') + `:</b>  ${item.subsidiary}</td></tr></table>` }">
                  <!--CIcon name="cil-low-vision" /-->
                  <font-awesome-icon icon="fa-solid fa-eye" />
                </CButton>
                <CInputCheckbox class="float-left" :checked="!!item.checked" :custom="true" :label="item.reference_1" />
              </template>
              <template v-else>{{ item.reference_1 }}</template>
            </td>
          </template>
          <template #value="{item}">
            <td>
              <!--CInput v-model="item.value" /-->
              <input class="form-control" v-model="item.value" @input="event => onItemChanged(item, event)" />
            </td>
          </template>
          <template #building_id="{item}">
            <td>
              <AMultiSelect v-model="item.building_id" :options="lists.buildings" :config="{ basic: true, label: 'identifier' }" @input="value => onBuildingChanged(value)" />
            </td>
          </template>
          <template #_details="{item}">
            <td>
              <!--CTextarea v-model="item.details" placeholder="Details" rows="1" /-->
              <textarea class="form-control" rows="1" v-model="item.details" />
            </td>
          </template>
          <template #options="{item}">
            <td style="white-space: nowrap;">
              <CButton class="mr-1" color="primary" @click="onSaveItem(item)"><CIcon name="cil-save"/></CButton>
              <CButton class="mr-1" color="danger" @click="onDeleteItem(item)"><CIcon name="cil-x"/></CButton>
              <CButton v-if="!item.is_aux" class="mr-1" color="secondary" @click="onAddItem(item)"><CIcon name="cil-plus"/></CButton>
              <CButton v-else class="mr-1" color="default"><CIcon name="cil-circle"/></CButton>
              <!--CButton class="mr-1" color="" @click="onViewItem(item)"><CIcon name="cil-low-vision"/></CButton-->
              <CButton class="mr-1" color="info" @click="onViewItem(item)"><font-awesome-icon icon="fa-solid fa-eye"/></CButton>
            </td>
          </template>
        </ACRUDTable>
      </CCol>
    </template>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Banking-DataImport',
  mixins: [formMixin, crudMixin],
  computed: {
    isDisabled() {
      //return !this.form.bank_account_id || !this.form.files.length || !this.form.bank_statement_file_type_id || !this.form.separator
      return !this.form.bank_account_id || !this.form.files.length || !this.form.bank_statement_file_type_id
    },
    transaction_type() {
      return this.$route.meta.expenses ? 'EGRE' : 'INGR'
    },
    transaction_type_name() {
      return this.$route.meta.expenses ? 'Expenses' : 'Income'
    },
    resumeErrors() {
      if (this.file_errors.length) {
        let resume = []
        for (const row of this.file_errors) {
          resume = resume.concat(row.errors)
        }
        return [...new Set(resume)].sort()
      }
      return []
    },
    pending() {
      if (this.filter === 'All') {
        return this.tables.pending
      } else {
        const identified = this.filter === 'Identified'
        return this.tables.pending.filter(item => item._identified === identified)
      }
    }
  },
  data() {
    return {
      form: {
        files: [],
        type: '',
        append: false,
        separator: ',',
        extensions: '..',
        heading_row: true,
        bank_account_id: 0
      },
      error: {},
      status: {},
      //config: {},
      type_name: '',
      file_errors: [],
      bank_statement_file_types: [],
      alertCounter: 0,
      show_append: false,
      show_upload: false,
      isMultiple: false,
      isUpdate: false,
      filter: 'All',
      lists: {},

      tables: {
        monthly: [],
        pending: [],
        bank_statements: []
      },

      fields: [],
      //types: [],
      separators: [
        { id: ',', name: this.$t('Comma (,)') },
        { id: ';', name: this.$t('Colom (;)') },
        { id: '|', name: this.$t('Pipe (|)') }
      ]
    }
  },
  created() {
    this.getData()
    this.type_name = this.transaction_type_name
    this.form.type = this.transaction_type
    if (_.isEmpty(this.$v.$form)) this.parseValidator({ type: 'required' }, { validation: { required: 'El campo :attribute es obligatorio.' } }, true) // TODO: messages should be global and cached!
  },
  methods: {
    getData() {
      const self = this
      self.$http
        //.get('admin/banking/lists')
        .get('admin/banking', { type_id: self.transaction_type })
        .then(response => {
          self.lists = response.data._lists || {}
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    parseData(data) {
      this.tables = {
        monthly: [],
        pending: [],
        bank_statements: []
      }

      const monthly = {}
      for (const bank_statement of data.bank_statements) {
        monthly[bank_statement.month_id] = { month_id: bank_statement.month_id, aux: 0, rows: 0, identified: 0, non_identified: 0, value: 0, applied: 0, pending: 0, ...(monthly[bank_statement.month_id] || {}) }
        monthly[bank_statement.month_id].aux += bank_statement.aux
        monthly[bank_statement.month_id].rows += bank_statement.rows
        monthly[bank_statement.month_id].identified += bank_statement.identified
        monthly[bank_statement.month_id].non_identified += bank_statement.non_identified
        monthly[bank_statement.month_id].value += bank_statement.value

        bank_statement.bank_statement_details.forEach(item => {
          item.identified = !!item.building_id
          return item
        })

        this.tables.pending = [...(this.tables.pending || []), ...bank_statement.bank_statement_details]

        const bank = this.lists.banks.find(item => item.id === bank_statement.bank_id) || {}
        const bank_statement_file_type = this.lists.bank_statement_file_types.find(item => item.id === bank_statement.bank_statement_file_type_id) || {}
        bank_statement.bank = bank.name || '--'
        bank_statement.bank_statement_file_type = bank_statement_file_type.name || '--'

        this.tables.bank_statements.push(bank_statement)
      }

      //console.log(Object.values(monthly))

      this.tables.monthly = Object.values(monthly)
    },

    onBankAccountChanged(event) {
      const types = ['ALL', this.transaction_type]

      this.bank_statement_file_types = []
      if (event.bank_id) this.bank_statement_file_types = this.lists.bank_statement_file_types.filter(item => item.bank_id === event.bank_id && types.indexOf(item.type_id) >= 0)

      this.form.files = []
      this.form.bank_statement_file_type_id = ''
      this.$refs.files.resetFiles()
    },
    onTypeChanged(event) {
      //console.log(event)
      this.success = false
      this.type_name = this.transaction_type_name + ' - ' + event.name
      this.file_errors = []
      this.alertCounter = 0
      this.isUpdate = !!event.update
      this.show_append = !!event.append

      const bank_statement_file_type = this.bank_statement_file_types.find(item => item.id === event.id)
      this.isMultiple = !!bank_statement_file_type.multiple

      this.form.files = []
      this.form.extensions = bank_statement_file_type.extensions
      this.form.heading_row = !!bank_statement_file_type.heading
      this.$refs.files.resetFiles()
    },

    onFileSelected(event) {
      this.success = false
      this.file_errors = []
      this.alertCounter = 0
      //this.form.files = event.target.files[0]
      this.form.files = []
      for (let i = 0; i < event.target.files.length; i++) {
        this.form.files.push(event.target.files[i])
      }
    },

    onBuildingChanged(event, index, key, type) {
      console.log(event, index)
    },

    findIndex(id) {
      // TODO: Find index
      return this.tables.pending.findIndex(item => item.id === id)
    },
    onAddItem(item) {
      const index = this.findIndex(item.id)

      if (index >= 0) {
        const new_item = { ...item }
        new_item._parent = index
        new_item.id = 0 //'new'
        new_item.value = 0
        new_item.is_aux = true
        new_item.parent_id = item.id
        new_item.building_id = null
        new_item.channel = `LNAUX (${item.id}) ${item.channel}`

        const self = this
        self.$http
          .post('admin/bank_statement_details', new_item)
          .then(response => {
            new_item.id = response.data.bank_statement_detail.id
            self.tables.pending.splice(index + 1, 0, new_item)
            self.showAlert('Row created.')
          })
          .catch(error => {
            console.error(error)
            self.showAlert(`There was an error.`, 'danger')
          })
      }
    },
    onSaveItem(item) {
      //const index = this.findIndex(item.id)
      const self = this
      self.$http
        .put('admin/bank_statement_details', item)
        .then(response => {
          console.log(response)
          self.showAlert('Row saved.')
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
        })
    },
    onDeleteItem(item) {
      const index = this.findIndex(item.id)

      if (item.id) {
        // TODO: Confirm?
        const self = this
        self.$http
          .delete('admin/bank_statement_details', item)
          .then(response => {
            console.log(response)
            self.showAlert('Row deleted.')
            this.tables.pending.splice(index, 1)
          })
          .catch(error => {
            console.error(error)
            self.showAlert(`There was an error.`, 'danger')
          })
      } else {
        this.tables.pending.splice(index, 1)
      }
    },
    onViewItem(item) {
      console.log('onViewItem', item)
    },
    onItemChanged(item) {
      if (item.is_aux) {
        const p_index = this.findIndex(item.parent_id)
        const parent = this.tables.pending[p_index]
        const children = this.tables.pending.filter(child => child.parent_id === item.parent_id && child.id !== item.id)
        let total = parent.statement_value
        for (const child of children) {
          total -= child.value
        }
        total -= item.value
        this.tables.pending[p_index].value = total
      }
    },

    resetForm() {
      // Reset form
      //this.form.type = ''
      this.form.files = []
      this.form.append = false
      this.form.extensions = '..'
      this.form.heading_row = true
      this.form.bank_account_id = ''
      this.form.bank_statement_file_type_id = ''
      this.$refs.files.resetFiles()
    },

    setStatus(rows) {
      this.alertCounter = 10
      const br = '<br />'
      let status = 'danger'
      let message = this.$t('The file was not imported') + br // TODO: Multiple ???

      if (!rows) message += this.$t('The file seems to have a wrong format') + br
      else {
        const Ok = rows.processed - (rows.empty + rows.error)
        if (rows.error) message += ` - ${this.$t('The file contains errors')}`
        else if (rows.processed === rows.empty) {
          status = 'warning'
          message += ` - ${this.$t('The file does not contains any valid data')}`
        } else if (Ok) {
          status = 'success'
          message = this.$t('The file was imported successfully')
        }

        // TODO: Multiple ???

        // eslint-disable-next-line prettier/prettier
        message = `${this.type_name}: ${message} '${this.form.files[0].name}' <br /><br />` + ` - ${this.$t('Ok')}: <b>${Ok}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Empty')}: <b>${rows.empty}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Ignored')}: <b>${rows.ignored}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Error')}: <b>${rows.error}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Processed')}: <b>${rows.processed - rows.empty - rows.ignored}</b> ${this.$t('rows')}.<br />`
      }
      this.status = { class: status, message }
    },

    setStatusFiles(files) {
      let message = ''
      for (const file of files) {
        console.log(file)
        this.alertCounter = 10
        const br = '<br />'
        let status = 'danger'
        let _message = this.$t('The file was not imported') + br // TODO: Multiple ???

        if (!file._rows) _message += this.$t('The file seems to have a wrong format') + br
        else {
          const Ok = file._rows.processed - (file._rows.empty + file._rows.error + file._rows.ignored) // + file._rows.duplicated)
          if (file._rows.error) _message += ` - ${this.$t('The file contains errors')}`
          else if (file._rows.processed === file._rows.empty) {
            status = 'warning'
            _message += ` - ${this.$t('The file does not contains any valid data')}`
          } else if (Ok) {
            status = 'success'
            _message = this.$t('The file was imported successfully')
          }

          // TODO: Multiple ???
          const processed = file._rows.processed - file._rows.empty

          // eslint-disable-next-line prettier/prettier
          //message += `${this.type_name}: ${_message} '${file.file_name}' <br /><br />` + ` - ${this.$t('Ok')}: <b>${Ok}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Error')}: <b>${file._rows.error}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Empty')}: <b>${file._rows.empty}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Ignored')}: <b>${file._rows.ignored}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Duplicated')}: <b>${file._rows.duplicated}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Processed')}: <b>${processed}</b> ${this.$t('rows')}.<br /><br />`
          message += `${this.type_name}: ${_message} '${file.file_name}' <br /><br />` + ` - ${this.$t('Ok')}: <b>${Ok}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Error')}: <b>${file._rows.error}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Empty')}: <b>${file._rows.empty}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Ignored')}: <b>${file._rows.ignored}</b> ${this.$t('rows')}.<br />` + ` - ${this.$t('Processed')}: <b>${processed}</b> ${this.$t('rows')}.<br /><br />`
        }
        this.status = { class: status, message }
      }
    },

    submit() {
      this.status = {}
      if (this.isDisabled) return

      this.form.type = this.transaction_type

      const formData = new FormData()
      for (const key in this.form) {
        if (key === 'files') {
          for (let i = 0; i < this.form.files.length; i++) {
            formData.append('files[' + i + ']', this.form.files[i])
          }
        } else {
          formData.append(key, this.form[key])
        }
      }
      if (this.isUpdate) formData.append('is_update', true)

      let alert = 'There was an error importing the data file.'

      const self = this
      self.$http
        .post(`admin/banking/import`, formData, [0])
        .then(response => {
          //self.setStatus(response.data._rows)
          self.setStatusFiles(response.data.files)
          self.resetForm()
          if (response.status === 200) {
            //console.log('Upload Ok.')
            self.showAlert('Upload successful.')
          } else self.showAlert(alert, 'danger')
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response) {
            if (error.response.status === 422) {
              self.setErrors(error.response.data.errors || {})
            } else if (error.response.status === 400) {
              alert = 'We found errors importing the file.' // TODO: better message
              self.setStatus(error.response?.data?._rows)
              self.file_errors = [{ row: self.$t('All'), errors: [self.$t('The file seems to have a wrong format')] }] // [self.$t('Invalid data.')] }]
              if (error.response.data._errors) {
                self.file_errors = error.response.data._errors
              }
            }
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            self.showAlert(alert, 'danger')
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.status.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(alert, 'danger')
            //self.goBack() // TODO: login ?
          }
          self.resetForm()
        })
    }
  }
}
</script>

<style lang="scss" scoped1>
.table-center {
  margin: auto !important;
}
.table-sm {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
.multiselect {
  margin-bottom: -5px;
}
/*.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}*/
</style>
